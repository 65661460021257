import { useParams } from "react-router";

import blogs from '../assets/blog/blogs.json';
import Container from '../components/Container';
import BlogGallery from '../components/BlogGallery';
import Markdown from '../components/Markdown';
import Error from '../components/Error';
import { Utils } from '../Utils';

const Blog = () => {
    const { publishDate } = useParams();
    let children, margin = true;
    if (!publishDate) {
      margin = false;
      const text = `
  These are writeups of fun and interesting projects that I've done over the years. The goal is to educate and amuse,
  I will leave it up to the reader to decide whether or not I have succeeded.
      `
      children = (
        <>
          <div className="left-header">Blog</div>
          <Markdown children={text} />
          <BlogGallery blogs={blogs} />
        </>
        
      );
    } else if (publishDate) {
      const blog = blogs.find(b => b.publish_date === publishDate);
      const byline = (
        <>
          <span>
            <span className="author">{blog.author}</span>
            <span className="publish-date">{`${Utils.formatDate(blog.publish_date)}`}</span>
          </span>
          <span className="reading-time">{`${Utils.estimateReadingTime(blog.content)} min read`}</span>
        </>
      );

      children = <Markdown mode="blog" byline={byline} title={blog.title} children={blog.content} />;
    } else {
      const msg = "No blog found on " + publishDate;
      children = <Error code={404} msg={msg} />;
    }
  
    return (
      <Container page="Blog" margin={margin}>{children}</Container>
    );
  }
  
  export default Blog;
const Error = props => {
  const { code, msg } = props;
  const code_msg = code + ' Error: ';
  return (
    <div className="row error-box">
        <span className="bold">{code_msg}</span>
        <span className="ml1">{msg}</span>
    </div>
  )
}

export default Error;
import Gallery from "./Gallery";
import cardGameImg from '../assets/images/card-game.png';

const baseUrl = 'https://cerebral-cereal-builds.s3.eu-west-2.amazonaws.com';
const apps = [
  {
    name: "Project Anicetus: OSX",
    image: cardGameImg,
    info: "High fantasy trading card game - Mac OS build.",
    icon: 'apple',
    iconStyle: 'fab',
    url: baseUrl + '/osx.zip',
  },
  {
    name: "Project Anicetus: Android",
    image: cardGameImg,
    info: "High fantasy trading card game - Android build.",
    icon: 'google-play',
    iconStyle: 'fab',
    url: baseUrl + '/android.apk',
  },
];

const DevGallery = props => {
  const { mode = "route", title } = props;
  return (
    <Gallery mode={mode} title={title} apps={apps} />
  )
}

export default DevGallery;
import React from 'react';
import Component from '../components/Component';
import Container from '../components/Container';
import AppGallery from '../components/AppGallery';

class Home extends Component {
  render() {
    return (
      <Container page="Home" margin={false}>
        <div className="body big-margin">
          <div className="title  no-select">Cerebral Cereal</div>
          <div className="tagline  no-select mb2">Creating free apps for all with no strings attached.</div>
        </div>
        <div className="h1 m2 mrauto">Our Projects</div>
        <AppGallery mode="store" />
      </Container>
    );
  }
}

export default Home;

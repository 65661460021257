import { useNavigate } from "react-router-dom";
import Icon from './Icon';
import { Utils } from '../Utils';

const BlogGallery = props => {
  const { blogs } = props;
  const router = useNavigate();

  const navigate = blog => {
    router(blog.publish_date);
  }

  const getBlogDescription = blog => {
    return (
      <>
        <span className="publish-date">{`${Utils.formatDate(blog.publish_date)}`}</span>
        <span>
          <span className="reading-time">{`${Utils.estimateReadingTime(blog.content)} min read`}</span>
          <Icon icon="chevron-right" size="s" className="disabled-color" />
        </span>
        
      </>
    );
  }

  return (
    <div className="blog gallery b3">
      {blogs.map((blog, i) => {
        return (
          <div className="row" key={i} onClick={() => navigate(blog)}>
            <div className="content">
              <div className="text">
                <div className="rowTitle">{blog.title}</div>
                <div className="desc">{getBlogDescription(blog)}</div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  );
}

export default BlogGallery;
import Container from '../components/Container';
import { Utils } from '../Utils';

const IMAGES = [
    "https://cdn.midjourney.com/9d0d00b9-d113-4a19-8b65-aaa079df1a64/0_2.png",
    "https://cdn.midjourney.com/d0a27f3c-1c05-48d1-a238-c12574795697/0_0.png",
    "https://cdn.midjourney.com/64055a67-8333-42ed-9982-35a3405524d7/0_3.png",
    "https://cdn.midjourney.com/a058591a-a9f0-4751-a574-86aafdd430b2/0_0.png",
    "https://cdn.midjourney.com/3d36cada-63aa-4b53-b24b-e862bfec1554/0_2.png",
    "https://cdn.midjourney.com/9d0d00b9-d113-4a19-8b65-aaa079df1a64/0_0.png",
    "https://cdn.midjourney.com/a058591a-a9f0-4751-a574-86aafdd430b2/0_1.png",
    "https://cdn.midjourney.com/e8385a62-103e-41bf-9c7f-d6c05ee54224/0_3.png",
    "https://cdn.midjourney.com/02549894-24f3-411b-9495-23ab54932890/0_0.png",
    "https://cdn.midjourney.com/02549894-24f3-411b-9495-23ab54932890/0_1.png",
    "https://cdn.midjourney.com/db9f7d43-c110-4948-824d-47f1e4fe9863/0_1.png",
    "https://cdn.midjourney.com/ba161dfc-d5d8-4a1a-89a6-a0c6dfba3e22/0_2.png",
    "https://cdn.midjourney.com/2a90769e-f9f5-4089-b4b0-0bddc8d5d5cc/0_1.png",
    "https://cdn.midjourney.com/2a90769e-f9f5-4089-b4b0-0bddc8d5d5cc/0_0.png",
    "https://cdn.midjourney.com/2a90769e-f9f5-4089-b4b0-0bddc8d5d5cc/0_2.png",
    "https://cdn.midjourney.com/db9f7d43-c110-4948-824d-47f1e4fe9863/0_3.png",
    "https://cdn.midjourney.com/a058591a-a9f0-4751-a574-86aafdd430b2/0_2.png",
    "https://cdn.midjourney.com/9e7d4833-ceea-443b-be0b-10af7e19f556/0_1.png",
    "https://cdn.midjourney.com/129ad9a6-4128-4236-a3d9-e98b4514e304/0_1.png",
    "https://cdn.midjourney.com/ba161dfc-d5d8-4a1a-89a6-a0c6dfba3e22/0_1.png",
    "https://cdn.midjourney.com/e9dfad8f-fe4a-4771-bbe8-959a6873b1c5/0_0.png",
    "https://cdn.midjourney.com/b30ce891-85c9-4a51-b4d9-1790cb04c8c9/0_2.png",
    "https://cdn.midjourney.com/9e7d4833-ceea-443b-be0b-10af7e19f556/0_2.png",
    "https://cdn.midjourney.com/85571f25-cc6e-44fc-ab7f-d6b4cc9fa7b6/0_2.png",
    "https://cdn.midjourney.com/9d0d00b9-d113-4a19-8b65-aaa079df1a64/0_1.png",
    "https://cdn.midjourney.com/e9dfad8f-fe4a-4771-bbe8-959a6873b1c5/0_2.png",
    "https://cdn.midjourney.com/b92d3c50-a25e-4726-8463-605f44e3c901/0_1.png",
    "https://cdn.midjourney.com/57f23be6-7a4c-4669-95b9-32b8da37cb40/0_2.png",
    "https://cdn.midjourney.com/26bb7e05-d674-4f1b-9d48-a3ebafe4043f/0_3.png",
    "https://cdn.midjourney.com/2144fc2e-cf8f-4b80-9f96-e57114d257a8/0_2.png",
    "https://cdn.midjourney.com/2e0481f5-3807-4ce2-b351-899738112aea/0_0.png",
    "https://cdn.midjourney.com/02549894-24f3-411b-9495-23ab54932890/0_2.png",
    "https://cdn.midjourney.com/b92d3c50-a25e-4726-8463-605f44e3c901/0_0.png",
    "https://cdn.midjourney.com/962f6e6f-9a8c-4918-9778-c599cb99937f/0_0.png",
    "https://cdn.midjourney.com/e8385a62-103e-41bf-9c7f-d6c05ee54224/0_1.png",
    "https://cdn.midjourney.com/6f1d5939-d003-4399-aee5-fdb4768a2679/0_1.png",
    "https://cdn.midjourney.com/90cc62d7-2f9f-40e1-a0df-5e06678b1cb9/0_1.png",
    "https://cdn.midjourney.com/26bb7e05-d674-4f1b-9d48-a3ebafe4043f/0_0.png",
    "https://cdn.midjourney.com/2144fc2e-cf8f-4b80-9f96-e57114d257a8/0_1.png",
    "https://cdn.midjourney.com/57f23be6-7a4c-4669-95b9-32b8da37cb40/0_3.png",
    "https://cdn.midjourney.com/94de1a3e-0da3-41cf-88dd-dc3804005016/0_3.png",
    "https://cdn.midjourney.com/90cc62d7-2f9f-40e1-a0df-5e06678b1cb9/0_0.png",
    "https://cdn.midjourney.com/9d29743e-6554-4f45-aadb-3a68b1ace74d/0_2.png",
    "https://cdn.midjourney.com/f42f6ea8-4b8e-4844-b62f-fbbcdb5db965/0_3.png",
    "https://cdn.midjourney.com/a5b9e09f-c8f6-448b-822f-6e14125b3108/0_2.png",
    "https://cdn.midjourney.com/26bb7e05-d674-4f1b-9d48-a3ebafe4043f/0_2.png",
    "https://cdn.midjourney.com/961d8f5b-c154-4f17-bcc1-f770bd992f3b/0_2.png",
]

const PHRASES = [
    "Begin with determination, and success will follow.",
    "Small steps every day will lead to big changes.",
    "Believe in yourself, and you're halfway there.",
    "When you feel like quitting, remember why you started.",
    "Progress is progress, no matter how small.",
    "You're stronger than you think. Keep pushing forward.",
    "One week down! You're off to a great start.",
    "Embrace the journey and trust the process.",
    "Your only limit is your mind.",
    "It's not about being perfect; it's about effort.",
    "Today is a new day to make a positive change.",
    "You're creating a healthier, happier version of yourself.",
    "Consistency is the key to success.",
    "Two weeks in! Be proud of your commitment.",
    "Your future self will thank you.",
    "Strength grows in the moments you think you can't go on.",
    "The harder you work, the better the results.",
    "Don't compare yourself to others; focus on your own progress.",
    "Be patient, and results will come.",
    "Dream big, work hard, and stay focused.",
    "Three weeks strong! Keep up the amazing work.",
    "Celebrate every victory, no matter how small.",
    "You are your only competition.",
    "Every day is a chance to improve.",
    "Strive for progress, not perfection.",
    "Believe in the power of change.",
    "You've got this; never give up.",
    "Four weeks completed! Keep chasing your goals.",
    "Your hard work is paying off; don't stop now.",
    "Stay committed to your journey.",
    "The best project you'll ever work on is you.",
    "You are in control of your own destiny.",
    "Success is a series of small wins.",
    "Trust the timing of your life.",
    "Only 10 more days to go! You're almost there.",
    "Keep pushing, the finish line is in sight.",
    "You've come so far; be proud of your progress.",
    "8 days left! Stay strong and focused.",
    "You've got this; the end is near.",
    "6 days to go! Keep up the incredible work.",
    "Almost there! Your dedication is inspiring.",
    "Just 4 more days! You're doing an amazing job.",
    "The finish line is just around the corner.",
    "Only 2 days left! You're so close to your goal.",
    "Tomorrow is the final day! Stay strong and determined.",
    "Your perseverance has led you to this moment. Cherish your achievements and continue to be your best self.",
    "Congratulations! You've made it. Celebrate your incredible journey and accomplishment.",
]

const Countdown = () => {
    const numDays = Utils.dateDiff(Utils.getCurrentDate(), '2023-06-12');
    let i = IMAGES.length - numDays - 1;
    i = i >= IMAGES.length ? IMAGES.length - 1 : i;

    const imageUrl = IMAGES[i];
    const phrase = PHRASES[i];

    let daysText;
    if (numDays < 1) {
        daysText = "You're Done! 😄";
    } else if (numDays === 1) {
        daysText = "Last Day";
    } else {
        daysText = numDays + " Days";
    }

    return (
      <Container page="Countdown" className="countdown" showHeader={false}>
        <div className="days">{daysText}</div>
        <img className="mt2 mb2" src={imageUrl} alt="Chibi Elephant" />
        <div className="phrase">{phrase}</div>
      </Container>
    );
}

export default Countdown;
import { INIT_SETTINGS, SAVE_SETTINGS } from '../actions/types';
import { Utils } from '../Utils';

const DEFAULT_SETTINGS = {
  theme: 'dark',
};

const updateSettings = (state, update) => {
  return Utils.update(
    Utils.clone(state.settings),
    update,
  );
}

const settingsReducer = (state = DEFAULT_SETTINGS, action) => {
  switch (action.type) {
    case INIT_SETTINGS:
      return updateSettings(state, action.settings);

    case SAVE_SETTINGS: {
      const newSettings = updateSettings(state, action.settings);
      localStorage.setItem('settings', JSON.stringify(newSettings));
      return newSettings;
    }

    default:
      return state;
  }
};

export default settingsReducer;

import Container from "../components/Container";
import Error from "../components/Error";

const NoPage = () => {
  return (
    <Container>
      <Error code={404} msg="Page not found" />
    </Container>
  )
}

export default NoPage;
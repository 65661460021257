import Container from '../components/Container';
import Markdown from '../components/Markdown';

const aboutText = `
Cerebral Cereal is a collection of projects that we've put together mostly for our own enjoyment and learning. Hopefully the fruits of these projects can be of use to others.

The apps are all available for free, without any ads. This has primarily been a hobby and learning exercise, so we're giving without expecting anything in return.

We take privacy seriously and the apps use only the minimum permissions and user data storage required to deliver the product.

Currently our apps are only available on Android via the Play Store. In part this is because the Apple developer license is significantly more expensive than Google's. We may publish to iOS at a later date, if there's sufficient demand from iPhone users.

All of the projects have been influenced, if not outright invented, by my wonderful partner. She's also the main test user and her immeasurable patience is the main reason these projects have come to life.
`;

const About = () => {
    return (
      <Container page="About">
        <Markdown title="About Us" children={aboutText} />
      </Container>
    );
}

export default About;
import React from 'react';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faLink, faMoon, faSun, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faApple, faGooglePlay } from '@fortawesome/free-brands-svg-icons';
import { MathJaxContext } from 'better-react-mathjax';

import { CommonProvider } from "./contexts/CommonContext";
import Pages from './Pages';
import rootReducer from './reducers';
import './styles/main.css';
import './styles/syntaxHighlight.css';

const App = () => {
  library.add(faLink, faChevronRight, faMoon, faSun, faApple, faGooglePlay);
  const store = configureStore({reducer: rootReducer});
  
  return (
      <Provider store={store}>
        <MathJaxContext>
          <CommonProvider>
            <Pages />
          </CommonProvider>
        </MathJaxContext>
      </Provider>
  );  
}

export default App;

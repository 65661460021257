import React, { useContext, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from "react-router-dom";

import brain from '../assets/images/brain.svg';
import Icon from './Icon';
import { saveSettings } from '../actions/SettingsActions';
import { CommonContext } from '../contexts/CommonContext';

const Header = ({ page }) => {
  const settings = useSelector(state => state.settings);
  const dispatch = useDispatch();
  const { changeTheme } = useContext(CommonContext);

  const toggleTheme = useCallback(() => {
    const newTheme = settings.theme === 'dark' ? 'light' : 'dark';
    changeTheme(newTheme);
    dispatch(saveSettings({ theme: newTheme }));
  }, [settings.theme, changeTheme, dispatch]);

  const links = [
    {name: "Home", route: "/"},
    {name: "Blog", route: "/blog"},
    {name: "About", route: "/about"},
  ];

  const icon = settings.theme === 'dark' ? 'sun' : 'moon';

  return (
    <div className="header">
      <div className="row-container">
        <div className="row a-center">
          <NavLink to="/" className="logo-parent">
            <img src={brain} className="logo" alt="brain" />
          </NavLink>
          <NavLink to="/" className="logo-text">
            <div className="h1">Cerebral Cereal</div>
          </NavLink>
        </div>
        <div className="row">
          {links.map((link, i) => (
            <NavLink 
              key={i} 
              className={`link${page === link.name ? ' selected' : ''}`} 
              to={link.route}
            >
              {link.name}
            </NavLink>
          ))}
          <Icon icon={icon} size="lg" onClick={toggleTheme} />
        </div>
      </div>
    </div>
  );
};

export default Header;
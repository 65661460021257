import { useNavigate } from "react-router-dom";
import Icon from './Icon';

const Gallery = props => {
  const { mode = "route", apps } = props;
  const router = useNavigate();

  const navigate = app => {
    if (mode === 'store') {
      window.open('https://play.google.com/store/apps/details?id=' + app.playId,'_blank');
    } else if (app.url) {
      window.open(app.url, '_blank');
    } else {
      router(app.route);
    }
  }

  return (
    <div className="gallery b3">
      {apps.map((app, i) => {
        return (
          <div className="row" key={i} onClick={() => navigate(app)}>
            <div className="content">
              <img src={app.image} alt={app.name} />
              <div className="text">
                <div className="rowTitle">{app.name}</div>
                <div className="desc">{app.info}</div>
              </div>
              <div className="link">
                <Icon icon="chevron-right" size="l" className="disabled-color" />
              </div>
            </div>
          </div>
        )
      })}
    </div>
  );
}

export default Gallery;
import React, { useEffect, useState, useCallback } from 'react';

const DEFAULT_THEME = 'dark';
const MOBILE_WINDOW_WIDTH = 768;
export const CommonContext = React.createContext('default');

export const CommonProvider = ({ theme = DEFAULT_THEME, children }) => {
  const [currentTheme, setCurrentTheme] = useState(theme);

  const [isMobile, setIsMobile] = useState(window.innerWidth < MOBILE_WINDOW_WIDTH);
  useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth < 768);
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  const changeTheme = useCallback((newTheme, callback) => {
    if (newTheme !== currentTheme) {
      setCurrentTheme(newTheme);
      if (callback) {
        callback();
      }
    } else if (callback) {
      callback();
    }
  }, [currentTheme]);

  const context = {
    theme: currentTheme,
    isMobile,
    changeTheme,
  };

  return (
    <CommonContext.Provider value={context}>
      {children}
    </CommonContext.Provider>
  );
};
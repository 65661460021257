import React from 'react';
import Component from './Component';
import Header from './Header';


class Container extends Component {
  componentDidMount = () => {
    document.title = 'Cerebral Cereal | ' + this.props.page;
  }

  render = () => {
    const { page, children, className, showHeader=true } = this.props;
    let classes = "container ";
    if (className) classes += className;
    
    return (
      <>
        {
        showHeader && (
          <>
            <Header page={page} />
            <div className="header-pad" />
          </>
        )}
        <div className={classes}>{children}</div>
      </>
    );
  }
}

export default Container;
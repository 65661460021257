const _typeOf = value => {
  var s = typeof value;
  if (s === 'object') {
    if (value) {
      if (value instanceof Array) s = 'array';
    } else s = 'null';
  }
  return s;
}

/** Creates a copy of any Object. */
const _clone = _orig => {
  if (_typeOf(_orig) === 'object') {
    return Object.assign( Object.create( Object.getPrototypeOf(_orig)), _orig);
  } else if (_typeOf(_orig) === 'array') {
    return _orig.slice(0);
  }
}

export class Utils {
  /** Creates a copy of any Object. */
  static clone = obj => _clone(obj);

  /** Updates an old object with a new one, returning a completely new Object. */
  static update = (prev, next) => {
    return Object.assign({}, prev, next);
  }

  /** Equivalent of the map function, for objects. */
  static mapObject = (obj, fn) => {
    return Object.fromEntries(
      Object.entries(obj).map(
        ([k, v], i) => [k, fn(v, k, i)]
      )
    );
  }

  static dateDiff(fromDateStr, toDateStr) {
    const fromDate = new Date(fromDateStr);
    const toDate = new Date(toDateStr);
  
    // Set hours, minutes, seconds, and milliseconds to 0 to only consider the date part
    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(0, 0, 0, 0);
  
    // Calculate the time difference in milliseconds
    const timeDifference = toDate - fromDate;
  
    // Convert the time difference to days
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  
    return daysDifference;
  }

  static getCurrentDate() {
    const date = new Date();
  
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based, so add 1
    const day = date.getDate().toString().padStart(2, '0');
  
    return `${year}-${month}-${day}`;
  }

  /**
   Formats a YYYY-MM-DD as a readable date.
  */
   static formatDate = (dateString) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
    // Function to add suffix to date
    function getDaySuffix(day) {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1:  return "st";
        case 2:  return "nd";
        case 3:  return "rd";
        default: return "th";
      }
    }
    return `${day} ${months[monthIndex]}, ${year}`;
  }

  /**
   Estimates reading time based on average reading time
  */
  static estimateReadingTime = (markdownText) => {
    let cleanedText = markdownText.replace(/```[\s\S]*?```/g, '');  // Ignore code blocks
    cleanedText = cleanedText.replace(/\$\$[\s\S]*?\$\$/g, '');  // Ignore equations
    cleanedText = cleanedText.replace(/[#>*`~\-_=[\]!()\\]/g, "").replace(/\s\s+/g, ' ');  // Strip formatting
    const wordCount = cleanedText.split(' ').filter(Boolean).length;
    const wordsPerMinute = 200; // Average reading speed (words per minute)
    const readingTime = Math.ceil(wordCount / wordsPerMinute);
    return readingTime;
  }
}
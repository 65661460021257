import React, { useMemo } from 'react';

import ReactMarkdown from 'react-markdown';
import { MathJax } from 'better-react-mathjax';
import {Prism as SyntaxHighlighter, createElement as createSyntaxHighlightElement} from 'react-syntax-highlighter';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';


function rowRenderer({ rows, stylesheet, useInlineStyles }) {
  const elements = rows.map((row, i) => (
    createSyntaxHighlightElement({
      node: row,
      stylesheet,
      useInlineStyles,
      key: i,
    })
  ));

  for (let i = 0; i < elements.length; i++) {
    if (elements[i].props.children) {
      const children = elements[i].props.children;
      const key = children.length - 1;
      const lastToken = children[key];
      if (lastToken.props) {
        let className = lastToken.props.className || '';
        className += ' last-of-line';
        children[key] = React.createElement(
          lastToken.type,
          {
            ...lastToken.props,
            key,
            className: className.trim(),
          },
          lastToken.props.children,
        )
      }
    }
  }

  return (
    <>
    {elements}
    </>
  );
}

const Link = React.memo(({ children, ...props }) => {
  return <a {...props} target="_blank" rel="noopener noreferrer">{children}</a>;
});

const Paragraph = React.memo(({ children, ...props}) => {
  const _children = React.Children.toArray(children);
  let className = props.className || '';
  if (_children.length > 0 && _children[0].type === 'img') {
    className = className ? className + " img-container" : "img-container";
  } else if (_children.length > 0 && _children[0].type === undefined && children[0].startsWith('$$')) {
    return <MathJax>{children}</MathJax>;
  } else if (_children.length === 3 && _children[0] === "<youtube>" && _children[2] === "</youtube>") {
    return <LiteYouTubeEmbed id={_children[1]} />;
  }

  className = className.trim();
  if (className) {
    return <p className={className}>{children}</p>
  } else {
    return <p>{children}</p>
  }
});

const CodeBlock = React.memo(({ children, inline, ...props}) => {
  let className = props.className || '';
  const match = /language-(\w+)/.exec(className || '');
  if (!inline && match) {
    const preClass = "code-container";
    const codeClass = "language-" + match[1];
    return (
      <SyntaxHighlighter
        showLineNumbers
        PreTag="div"
        className={preClass}
        codeTagProps={{className: codeClass}}
        children={String(children).replace(/\n$/, '')}
        useInlineStyles={false}
        language={match[1]}
        renderer={rowRenderer}
      />
    );
  } else {
    return <code>{children}</code>;
  }
});


const Markdown = ({ mode = "regular", byline = null, children, title }) => {
  let classname = "article";
  if (mode === "blog") {
    classname += " blog";
  }

  let headerClass = "h1"
  if (!byline) {
    headerClass += " no-byline";
  }

  const components = useMemo(() => ({
    a: Link,
    p: Paragraph,
    code: CodeBlock,
  }), []);

  return (
    <div className="article-parent">
      {title && <div className={headerClass}>{title}</div>}
      {byline && <div className="byline">{byline}</div>}
      <div className={classname}>
        <ReactMarkdown
          children={children}
          components={components}
        />
      </div>
    </div>
  )
}

export default Markdown;
